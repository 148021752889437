.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
    z-index: 999; /* Ensure it appears above other elements */
  }
  
  .content {
    /* Your regular content styles */
  }
  
  .content.disabled {
    pointer-events: none; /* Disable pointer events on the disabled content */
    opacity: 0.5; /* Optionally, reduce opacity for a disabled appearance */
  }
  